const MENU_BTN = document.querySelector('.header__menu-btn')
const MENU = document.querySelector('.header__menu')
const ITEM_MORE = document.querySelectorAll('.item-more')

// Функция появления кнопки прокрутки вверх
const SCROLL_BTN = document.querySelector('.top-scroll')

// Модальные окна
const closeModalBtn = document.querySelectorAll('.closeModal')
const openModal = document.querySelectorAll('.openModal')
const modalItems = document.querySelectorAll('.modal')
const MODAL_BG = document.querySelector('.modal-bg')

// Раскрытие мобильного меню
MENU_BTN.addEventListener('click', () => {
    MENU_BTN.classList.toggle('active')
    MENU.classList.toggle('active')
})


//

ITEM_MORE.forEach((item) => {

    item.addEventListener('click', (event) => {

        if (item.parentElement.classList.contains('active')) {
            item.parentElement.classList.remove('active')
            item.innerText = 'Еще...'
        } else {
            item.parentElement.classList.add('active')
            item.innerText = 'Свернуть'
        }
    })
})

// Инициализация Слайдеров
const Slider = new Swiper('.slider__list', {
    loop: true,
    speed: 400,
    clickable: true,
    slidesPerView: "auto",
    centeredSlides: false,
    spaceBetween: 20,

    navigation: {
        nextEl: '.slider__button-next',
        prevEl: '.slider__button-prev',
    },

    breakpoints: {
        320: {
            spaceBetween: 20,
        },
        767: {
            spaceBetween: 20,
        },
    },
});

const Certificate = new Swiper('.certificate__slider', {
    speed: 400,
    clickable: true,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 20,

    navigation: {
        nextEl: '.certificate__button-next',
        prevEl: '.certificate__button-prev',
    },

    pagination: {
        el: '.certificate__pagination',
        type: 'bullets',
        clickable: true,
    },
});

// Функция появления кнопки прокрутки вверх
const FOOTER = document.querySelector('.footer')
let pageHeight = document.documentElement.scrollHeight
let footerHeight = FOOTER.clientHeight
let windowInnerHeight = window.innerHeight
let width = window.innerWidth

window.addEventListener('resize', () => {
    pageHeight = document.documentElement.scrollHeight
    footerHeight = FOOTER.offsetHeight
    windowInnerHeight = window.innerHeight
    width = window.innerWidth
})

let prev = 0

window.addEventListener('scroll', (event) => {

    let scrollPosition = pageHeight - footerHeight - 200
    let position = scrollY + windowInnerHeight

    if (scrollPosition <= position) {
        if (width <= 767) {
            SCROLL_BTN.style.bottom = '30px'
        }
        if (width < 1366) {
            SCROLL_BTN.style.bottom = '80px'
        }

    } else {
        SCROLL_BTN.style.bottom = '30px'
        if (width <= 767) {
            SCROLL_BTN.style.bottom = '75px'
        }
    }

    if (prev > scrollY && scrollY > 300) {
        SCROLL_BTN.classList.add('active')

    } else {
        SCROLL_BTN.classList.remove('active')
    }

    prev = scrollY
})

// Открытие/закрытие модальных окон
function closeModal() {
    MODAL_BG.classList.remove('active')
    modalItems.forEach((item) => {
        item.classList.remove('active')
    })
    // htmlScroll()
}

closeModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        closeModal()
    })
})

openModal.forEach((item) => {
    item.addEventListener('click', () => {
        modalItems.forEach((modals) => {
            if (modals.classList.contains(item.getAttribute('data-modal'))) {
                MODAL_BG.classList.add('active')
                modals.classList.add('active')
                // hideScroll()
            }
        })
    })
})

// Переключение табов
const TAB_ITEM = document.querySelectorAll('.tab-item')
function setActiveTab(activeTab) {
    const TAB = document.querySelectorAll('.product-desc__text')
    TAB.forEach((item) => {
        if (item.getAttribute('data-item') !== activeTab) {
            item.classList.remove('active')
        } else {
            item.classList.add('active')
        }
    })
}

setActiveTab('2')

TAB_ITEM.forEach((item) => {
    item.addEventListener('click', () => {
        TAB_ITEM.forEach((item) => {
            item.classList.remove('active')
        })
        item.classList.add('active')
        setActiveTab(item.getAttribute('data-item'))
    })
})

// Фильтр каталога
const CATALOG_BTN = document.querySelectorAll('.filter-item')
const CATALOG_ITEMS = document.querySelectorAll('.list-item')

function setActiveCatalog(activeCatalog) {
    CATALOG_ITEMS.forEach((item) => {
        if (item.getAttribute('data-filter') !== activeCatalog) {
            item.classList.add('hide')
        } else {
            item.classList.remove('hide')
        }
    })
}

CATALOG_BTN.forEach((item) => {
    item.addEventListener("click", () => {
        CATALOG_BTN.forEach((item) => {
            item.classList.remove('active')
        })
        item.classList.add('active')
        setActiveCatalog(item.getAttribute('data-filter'))
    })
})

//Прикрепить файл
$(document).ready(function () {
    $("#tubeForm").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#tubeFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#priceForm").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#priceFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#calcForm").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#calcFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#formUpload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#formUploadName").html(filename);
    });
});

//Тост об успешной отправке формы
const TOAST = document.querySelector('.toast')
function closeToast(){
    setTimeout(() => {
        TOAST.classList.remove('active')
    }, 2000)
}

//Отправка статистики
const googleStat = document.getElementById('googleStat')
const yandexStat = document.getElementById('yandexStat')
function sendStats() {
    if (googleStat) {
        googleStat.click()
    }

    if (yandexStat) {
        yandexStat.click()
    }
}

//Формирование формы аналитики
$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_meduim');
    let utm_compaign = url.searchParams.get('utm_compaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_compaign"]').val(utm_compaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    setDataClient()

});

function setDataClient() {
    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }
}

//Передача лида и формирование формы аналитики
$(function () {
    $('form').submit(function (e) {
        e.preventDefault();
        setDataClient()
        let $form = $(this),
            formID,
            formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function () {
            let fileName = 'Прикрепить файл';
            $('form').trigger('reset');
            $("#tubeFileName").html(fileName);
            $("#priceFileName").html(fileName);
            $("#calcFileName").html(fileName);
            $("#formUploadName").html(fileName);
            TOAST.classList.add('active');
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            closeModal()
            sendStats()
            closeToast()
        });
    });
});


